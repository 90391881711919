<template>
  <div id="lstUser" class="lstUser__wrap">
    <CCard>
      <CCardBody>
        <div class="flex1">
          <label>{{$t("LIST_USER_PAGE_LABEL_USER_MANAGEMENT")}}</label>
          <CButton :class="{'disabled': !hasPermissionAdd}" @click="createUser" class="btn btn-success btn-md btn-new">
            <!-- <span class="pr-1 pr--plus">
              <CIcon :content="cilPlus" />
            </span> -->
            + {{$t("LIST_USER_PAGE_BUTTON_ADD_USER")}}
          </CButton>
        </div>
        <ListUserTableComponent
          @delete-user="openModal"
          :limit="limit"
          :activePage="activePage"
          :users="users"
          :total="total"
          :hasPermissionEdit="hasPermissionEdit"
          :hasPermissionDelete="hasPermissionDelete"
          @pushPage="pushPage"
        />
        <div class="pagination-wrap pagination--user">
          <CPagination
            v-if="pages>1"
            :activePage.sync="pageInput"
            :pages="pages"
            align="end"
            size
            @update:activePage="pushPage"
          />
        </div>
      </CCardBody>
    </CCard>
    <CModal id="deleteModal" :show.sync="warningModal" :centered="true">
      <template #header>
        <CButtonClose @click="warningModal = false" />
      </template>
      <div class="">
        <div class="pb-4 tit-dele">
          <!-- <CIcon width="48px" class="deleteIcon" :content="cilTrash" /> -->
          <h4>{{$t("LIST_USER_PAGE_TITLE_MODAL_DELETE_ACCOUNT")}}</h4>
        </div>
        <div class="text-modal" v-html="$t('LIST_USER_PAGE_CONTENT_MODAL_DELETE_ACCOUNT', {name: nameToDelete})"></div>
      </div>

      <template #footer>
        <CButton
          class="cancel-btn"
          @click="warningModal = false"
          color="dark"
          variant="outline"
        >{{$t("LIST_USER_PAGE_BUTTON_MODAL_CANCEL")}}</CButton>
        <CButton @click="deleteUser" class="delete-btn">{{$t("LIST_USER_PAGE_BUTTON_MODAL_DELETE")}}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import ListUserTableComponent from "../../../../components/UserAccount/ListUserTableComponent";
import { cilTrash, cilMedicalCross, cibSolus, cilPlus } from "@coreui/icons";
import endpoints from "@/constants/endpoints";
import { mapState,mapActions } from "vuex";
import responseHelper from '@/utils/response-helper';
export default {

  data() {
    return {
      cilTrash,
      cilMedicalCross,
      cilPlus,
      warningModal: false,
      users: [],
      total: 0,
      limit: 5,
      activePage: 1,
      idToDelete: null,
      nameToDelete: "",
      pageInput: 1,
      hasPermissionEdit: false,
      hasPermissionAdd: false,
      hasPermissionDelete: false,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
    ...mapState({
      userPermission: state => state.auth.userPermission,
    }),
  },

  async mounted() {
    let data = await this.fetchUsers();
    if (data) {
      this.users = data.items;
      this.total = data.total;
    };
    await this.checkPermission();
  },

  methods: {
    ...mapActions(["getAction"]),
    async deleteUser() {
      try {
        this.warningModal = false;
        let result = await this.$http.delete(
          endpoints.deleteUser(this.idToDelete)
        );
        if (result.data) {
          this.$notify({
            group: "notifications",
            type: "success",
            title: this.$t("LIST_USER_PAGE_TITLE_NOTIFY_SUCCESS"),
            text: this.$t("LIST_USER_PAGE_TEXT_NOTIFY_SUCCESS"),
            duration: 1000
          });
        }
        let data = await this.fetchUsers();
        if (data) {
          this.users = data.items.slice();
        }
      } catch (err) {
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("LIST_USER_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
          duration: 2000
        });
      }
    },
    async createUser() {
      if(!this.hasPermissionAdd){
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t("LIST_USER_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err)
        });
        return
      }
      await this.getAction("create");
      this.$router.push("user-management/create-user");
    },
    async fetchUsers() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset
        };
        let result = await this.$http.get(endpoints.getUsers, { params });
        return result.data;
      } catch (err) {
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("LIST_USER_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
          duration: 2000
        });
      }
    },
    async pushPage(index) {
      this.activePage = index;
      console.log(this.activePage)
      let data = await this.fetchUsers();
      if (!data) {
        return;
      }
      this.users = data.items.slice();
    },
    openModal(id,name) {
      // let name = this.items.name;
      this.idToDelete = id;
      this.nameToDelete = name;
      console.log(this.idToDelete);
      console.log(this.nameToDelete);
      this.warningModal = true;
    },
    
    checkPermission(){
      if(this.userPermission.length){
        this.userPermission.forEach(el=>{
          if(el.name==="CREATE_USER" ){
            this.hasPermissionAdd = true;
          }
          else if(el.name==="UPDATE_USER"){
            this.hasPermissionEdit = true;
          }
          else if(el.name==="DELETE_USER"){
            this.hasPermissionDelete = true;
          }
        })
      }
    },
  },
  name: "ListUser",
  components: { ListUserTableComponent }
};
</script>

<style lang="scss">
#lstUser /deep/ {
  padding: 0 24px;
  $green: #4dc1a1;
  .flex1 {
    text-align: end;
    margin-bottom: 5px;
  }
  .deleteIcon {
    fill: #ff0000;
  }
  .modal-body{
    padding: 1.3rem 1.5rem;
    h4{
      font-size: 18px;
    }
  }
  .modal-content{
    width: 85%;
    .modal-header {
      border: none;
      padding: 0 8px;
      display: none;
    }
    .text-modal {
      strong{
        color: #3A4A7F;
        font-weight: 500;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      .delete-btn {
        background-color: #FF5555;
        color: #ffffff;
        width: 150px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
      }
      .cancel-btn {
        width: 150px;
        border: 2px solid #CBCBCB;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
        &:hover{
          background-color: #fff
        }
      }
    }
  }
  
  
}
.lstUser__wrap{
  padding: 0 24px;
  @media screen and ( max-width: 1024px ){
    padding: 0 15px;
  }
  .card-body{
    padding-top: 10px;
    padding-bottom: 10px;
    .flex1{
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
      @media screen and ( max-width: 500px ){
        flex-direction: column;
        align-items: center;
      }
      label{
        color: #657187;
        // font-family: "SF UI Display";
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0;
      }
      .btn-new {
        padding: 0;
        width: 170px;
        height: 32px;
        border-radius: 32px;
        background: #3B4B7E;
        border-color: unset;
        .pr--plus{
          svg{
            margin-top: 0;
          }
        }
      }
    }
    #lstUserTable{
      margin-bottom: 17px;
      thead{
        th{
          padding: 9px;
          color: #4D5666;
          // font-family: "SF UI Display";
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.5px;
          &:nth-last-of-type(2){
            text-align: center;
          }
        }
      }
      tbody{
        td{
          padding: 8px;
          color: #4D5666;
          // font-family: "SF UI Display";
          font-size: 14px;
        }
        .users-action{
          // padding: .75em 0;
          .remove-icon{
            margin-left: 20px !important;
            @media only screen and ( max-width: 1024px ){
              margin-left: 8px !important;
            }
          }
        }
      }
    }
    .pagination--user{
      .pagination{
        margin-bottom: 5px;
        .page-item{
          &:last-child,
          &:first-child{
            width: 34px;
            height: 35px;
          }
          &:not(:last-child):not(:first-child){
            width: 32px;
            height: 35px;
          }
        }
      }
    }
  }
}
</style>
