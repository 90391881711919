var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{attrs:{"id":"lstUserTable"}},[_c('CDataTable',{attrs:{"items":_vm.users,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":"","pagination":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage-1)*_vm.limit))])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('CBadge',[(item.user_sts==='ACTIVATED')?_c('img',{attrs:{"src":require("@/assets/images/ico-user-active.svg")}}):_vm._e(),(item.user_sts==='UNACTIVATED')?_c('img',{attrs:{"src":require("@/assets/images/ico-user-deactive.svg")}}):_vm._e()])],1)]}},{key:"role",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},_vm._l((item.role),function(e,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(e)),(index < item.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),0)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center users-action"},[_c('CLink',{attrs:{"title":_vm.$t('LIST_USER_TABLE_COMPONENT_TITLE_EDIT')},on:{"click":function($event){return _vm.edit(item.id)}}},[_c('img',{staticClass:"hand edit-icon",class:{'disabled': !_vm.hasPermissionEdit},attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]),_c('CLink',{attrs:{"title":_vm.$t('LIST_USER_TABLE_COMPONENT_TITLE_DELETE')},on:{"click":function($event){return _vm.remove(item.id,item.name)}}},[_c('img',{staticClass:"ml-3 hand remove-icon",class:{'disabled': item.id === _vm.userId || !_vm.hasPermissionDelete },attrs:{"src":require("@/assets/images/ico-del-user.svg"),"alt":""}})])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }