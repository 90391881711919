<template>
  <CCard id="lstUserTable">
    <CDataTable :items="users" :fields="fields" fixed hover striped bordered pagination>
      <template ##="{item, index}">
        <td>{{index + 1 + (activePage-1)*limit}}</td>
      </template>

      <template #status="{item}">
        <td class="text-center">
          <!-- <CBadge :color="getBadge(item.status)"> -->
          <CBadge>
            <img v-if="item.user_sts==='ACTIVATED'" src="@/assets/images/ico-user-active.svg"/>
            <img v-if="item.user_sts==='UNACTIVATED'" src="@/assets/images/ico-user-deactive.svg"/>
          </CBadge>
        </td>
      </template>
      <template #role="{item}">
        <td class="text-center">
          <span v-for="(e,index) in item.role" :key="index">
            {{e}}<span v-if="index < item.length - 1">, </span>
          </span>
        </td>
      </template>
      <template #action="{item}">
        <td class="text-center users-action">
          <!-- <span @click="edit(item.id)" class="fas fa-pen hand edit-icon" /> -->
          <!-- <span :class="{'disabled': item.id === userId }" class="fas fa-trash  hand remove-icon" @click="remove(item.id)" /> -->
          <CLink :title="$t('LIST_USER_TABLE_COMPONENT_TITLE_EDIT')" @click="edit(item.id)">
            <img src="@/assets/images/ico-edit-user.svg" :class="{'disabled': !hasPermissionEdit}" alt="" class="hand edit-icon"/>
          </CLink>
          <CLink :title="$t('LIST_USER_TABLE_COMPONENT_TITLE_DELETE')" @click="remove(item.id,item.name)">
            <img src="@/assets/images/ico-del-user.svg" :class="{'disabled': item.id === userId || !hasPermissionDelete }" alt="" class="ml-3 hand remove-icon"/>
          </CLink>

          <!-- <img src="@/assets/images/ico-edit-user.svg" :class="{'disabled': !hasPermissionEdit}" alt="" @click="edit(item.id)" class="hand edit-icon"/> -->
          <!-- <img :class="{'disabled': item.id === userId || !hasPermissionDelete }" class="ml-3 hand remove-icon" src="@/assets/images/ico-del-user.svg"/> -->
        </td>
      </template>
    </CDataTable>

  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      fields: [
        '#',
        {
          key: 'name',
          label:this.$t('LIST_USER_TABLE_COMPONENT_LABEL_NAME'),
        },
        {
          key: 'email',
          label:this.$t('LIST_USER_TABLE_COMPONENT_LABEL_EMAIL'),
        },
        {
          key: 'role',
          label:this.$t('LIST_USER_TABLE_COMPONENT_LABEL_ROLE'),
          _style: 'text-align: center'
        },
        {
          key: 'status',
          label:this.$t('LIST_USER_TABLE_COMPONENT_LABEL_STATUS'),
          _style: 'width: 12%; text-align: center'
        },
        {
          key: 'action',
          label:this.$t('LIST_USER_TABLE_COMPONENT_LABEL_ACTION'),
          _style: 'width: 12%; text-align: center'
        }
      ],
    };
  },
  props: ["users",'activePage','limit','hasPermissionEdit','hasPermissionDelete'],
  computed: {
    ...mapState({
      userId: state => state.auth.userId,
    }),
  },
  methods: {
    ...mapActions(['getSelectedUser']),
    getBadge(status) {
      return status === 'Active'
        ? 'success'
        : status === 'Locked'
        ? 'danger'
        : 'primary';
    },
    edit(id) {
      if (!this.hasPermissionEdit){
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_USER_TABLE_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: this.$t('LIST_USER_TABLE_COMPONENT_TEXT_NOTIFY_EDIT_ERROR')
        });
        return;
      }
      const userId = id;
      var user = this.users.filter(user => user.id === userId)[0];
      this.getSelectedUser(user);
      this.$router.push(`user-management/edit-user/${userId}`);
    },
    remove(id,name) {
      if (!this.hasPermissionDelete){
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_USER_TABLE_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: this.$t('LIST_USER_TABLE_COMPONENT_TEXT_NOTIFY_DELETE_ERROR')
        });
        return;
      }
      if (this.userId === id){
        return;
      }
      const userId = id;
      const userName = name;

      this.$emit('delete-user', userId,userName);
    },
  },
  name: 'ListUserTable'
};
</script>

<style lang="scss">
#lstUserTable {
  .table{
    margin-bottom: 0;
    .hand {
      cursor: pointer;
    }
    .edit-icon {
      color: #ffae00;
    }
    .remove-icon {
      color: rgba(236, 42, 42, 0.91);
      &.disabled{
        user-select: none;
        opacity: .5;
        cursor: auto;
      }
    }
  }
}
</style>
